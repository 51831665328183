export const fireVWOEvent = (name, data) => {
  console.log('fireVWOEvent')
  window.VWO = window.VWO || []
  window.VWO.event =
    window.VWO.event ||
    function () {
      window.VWO.push(["event"].concat([].slice.call(arguments)))
    }
    window.VWO.event(name, data)
}
